var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-1"
  }, [_c('div-table', {
    attrs: {
      "config": _vm.configList,
      "data": _vm.orders,
      "statusOrder": _vm.statusOrder,
      "zones": _vm.zones,
      "element": _vm.element,
      "limit": _vm.limit,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser,
      "loading": _vm.loading,
      "paginate": _vm.paginate,
      "CheckQteDefecteuseorderexist": _vm.CheckQteDefecteuseorderexist,
      "tabId": _vm.tabId,
      "statusShippChange": _vm.statusShippChange
    },
    on: {
      "reset": _vm.reset,
      "refresh": _vm.refresh,
      "showProducts": _vm.showProducts,
      "filtrer": _vm.filtrer,
      "Changelimit": _vm.Changelimit,
      "downloadOrders": _vm.downloadOrders,
      "search": _vm.search,
      "paginatation": _vm.paginatation,
      "CheckQteDefecteuseOrder": _vm.CheckQteDefecteuseOrder,
      "emtyTabId": _vm.emtyTabId,
      "selectAllCheckbox": _vm.selectAllCheckbox,
      "getRowDetail": _vm.getRowDetail
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }