<template>
  <div class="p-1">
    <div-table :config="configList" :data="orders" :statusOrder="statusOrder" :zones="zones" :element="element"
      @reset="reset" @refresh="refresh" @showProducts="showProducts" @filtrer="filtrer" @Changelimit="Changelimit"
      :limit="limit" :idWharhouse="idWharhouse" :currentUser="currentUser" @downloadOrders="downloadOrders"
      @search="search" :loading="loading" :paginate="paginate" @paginatation="paginatation"
      @CheckQteDefecteuseOrder="CheckQteDefecteuseOrder" :CheckQteDefecteuseorderexist="CheckQteDefecteuseorderexist"
      @emtyTabId="emtyTabId" @selectAllCheckbox="selectAllCheckbox" @getRowDetail="getRowDetail" :tabId="tabId"
      :statusShippChange="statusShippChange"></div-table>
  </div>
</template>

<script>
import config from "./config";
import lodash from "lodash"
export default {
  props: {
    currentUser: { type: Object },
    idWharhouse: { type: Object },
  },
  data() {
    return {
      configList: config.list,
      loading: false,
      CheckQteDefecteuseorderexist: false,
      formData: {
        customer: {},
      },
      orders: [],
      tabId: [],
      element: {},
      sellers: [],
      show_modal: false,
      limit: 50,
      arrayScv: [],
      filters: {},
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      statusOrder: [],
      zones: [],
      products: [],
      //currentUser: {},
      //idWharhouse:"",
      statusShippChange: [],
    };
  },
  async mounted() {
    if (this.currentUser && this.currentUser.type && this.idWharhouse) {
      if (
        this.currentUser.type == "Seller" ||
        this.currentUser.type == "ToRemind" ||
        this.currentUser.type == "TeleConsultant"
      ) {
        this.limit = 10;
        this.filters.limit = 10;
      }
      // await this.getUser();
      // await this.getwharhouseId();
      await this.getStatus();
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      else
        this.$f.objectToQueryString({
          limit: this.limit,
          "customer.country": await this.warhouseSelected,
        });

      this.filters = this.$f.QueryStringToobject();
      if (this.filters.name) await this.search(this.filters.name);
      else await this.getOrders(this.filters);

      await this.getZones();
      if (this.currentUser.type != "Seller") {
        await this.getProducts();
        this.setDataSelect("product", this.products, null);
        await this.getSellers();
        this.setDataSelect("seller", this.sellers, null);
      }
      this.setDataSelect("status", this.statusOrder, null);
      this.setDataSelect(
        "status_pending",
        [
          { name: "pending" },
          { name: "pending_unrached" },
          { name: "pending_remind" },
        ],
        null
      );

      if (this.currentUser.type == "Seller") {
        let pos = this.configList.columns
          .map(function (e) {
            return e.name;
          })
          .indexOf("Seller");
        if (pos != -1) this.configList.columns.splice(pos, 1);
        let pos_1 = this.configList.columns
          .map(function (e) {
            return e.type;
          })
          .indexOf("checkbox");
        if (pos_1 != -1) this.configList.columns.splice(pos_1, 1);
      }
    }
  },
  computed: {
    warhouseSelected() {
      return this.$store.getters["wharhouse/warhouseSelected"]({
        user: lodash.pick(this.currentUser, ["countries"]),
      });
    },
  },
  watch: {
    warhouseSelected: async function (oldVal, newVal) { },
    idWharhouse: async function (oldVal, newVal) {
      this.idWharhouse = oldVal;
      await this.reset();

      if (this.currentUser.type != "Seller") {
        await this.getSellers();
        await this.getProducts();
        await this.getZones();
        this.setDataSelect("seller", this.sellers, null);
      } else {
        this.limit = 10;
        this.filters.limit = 10;
      }
    },
  },
  methods: {
    selectAllCheckbox($event) {
      let columns = document.querySelectorAll(".rowCheckbox");

      this.tabId = [];
      if ($event.target.checked == true) {
        columns.forEach((column) => {
          column.checked = true;
          this.tabId.push({
            status: column.getAttribute("status"),
            id: column.id,
            seller: column.getAttribute("sellerid"),
            statusid: column.getAttribute("statusid"),
          });
        });
      } else {
        columns.forEach((column) => {
          column.checked = false;
        });
        this.tabId = [];
      }
    },
    getRowDetail(item, id) {
      // let rows = this.selectedRows;
      let itemInRow = this.tabId.filter((item) => item.id === id);
      let isItemInRow = itemInRow.length > 0;

      if (isItemInRow) {
        let pos = this.tabId
          .map(function (e) {
            return e.id;
          })
          .indexOf(id);
        this.tabId.splice(pos, 1);
      } else {
        this.tabId.push({
          status: item.status.name,
          id: id,
          seller: item.seller._id || item.seller,
          statusid: item.status._id || item.status,
        });
      }

      if (this.tabId.length === 0) {
        let columns = document.querySelectorAll(".checkallbbox");
        columns.forEach((column) => {
          column.checked = false;
        });
      }
    },
    emtyTabId() {
      let columns = document.querySelectorAll(".rowCheckbox");
      columns.forEach((column) => {
        column.checked = false;
      });
      this.tabId = [];
    },
    async getwharhouseId() {
      await this.$store
        .dispatch("wharhouse/getwharhouseId", {
          country: await this.warhouseSelected,
        })
        .then((res) => {
          this.idWharhouse = res;
        });
    },
    async Changelimit(val) {
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.filters["limit"] = val;
      this.filters["page"] = 1;
      this.paginate.currentpage = 1;
      this.$f.objectToQueryString(this.filters);
      if (this.filters.name) await this.search(this.filters.name);
      else this.getOrders(this.filters);
    },
    sumProduct(data) {
      let product = "";
      for (let i in data) {
        if (data[i].product && data[i].product.name)
          product += data[i].product.name;
        if (data.length - 1 != i) product += " ,";
      }
      return product;
    },
    async downloadOrders() {
      this.arrayScv =
        typeof this.orders !== "object" ? JSON.parse(this.orders) : this.orders;

      for (let i in this.arrayScv) {
        this.arrayScv[i].IdOrder = this.arrayScv[i].id.replace("#", "");
        this.arrayScv[i].Seller = this.arrayScv[i].seller.fullName;
        this.arrayScv[i].Customer = this.arrayScv[i].customer.fullName;
        this.arrayScv[i].Phone = this.arrayScv[i].customer.phone;
        this.arrayScv[i].Products = this.sumProduct(this.arrayScv[i].details);
        this.arrayScv[i].ShippingAddress = this.arrayScv[i].customer
          .shippingAddress
          ? this.arrayScv[i].customer.shippingAddress
          : "";
        this.arrayScv[i].TotalPrice = this.arrayScv[i].totalPrice;
        this.arrayScv[i].OrderDate = this.$moment(this.arrayScv[i].date).format(
          "yyyy-MM-DD HH:mm"
        );
        this.arrayScv[i].Status = this.arrayScv[i].status.name;
      }
      for (let j in this.arrayScv) {
        delete this.arrayScv[j].statusCallcenter;
        delete this.arrayScv[j].teleConsultantConfirm;
        delete this.arrayScv[j].lastUnreachedDate;
        delete this.arrayScv[j].updatedAt;
        delete this.arrayScv[j].sameOrderUnreached;
        delete this.arrayScv[j].random;
        delete this.arrayScv[j].unreachedBy;
        delete this.arrayScv[j].totalPrice;
        delete this.arrayScv[j]._id;
        delete this.arrayScv[j].totalFees;
        delete this.arrayScv[j].teleConsultant;
        delete this.arrayScv[j].status;
        delete this.arrayScv[j].source;
        delete this.arrayScv[j].seller;
        delete this.arrayScv[j].processedPrice;
        delete this.arrayScv[j].processedAt;
        delete this.arrayScv[j].paidAt;
        delete this.arrayScv[j].isRefunded;
        delete this.arrayScv[j].isDeleted;
        delete this.arrayScv[j].details;
        delete this.arrayScv[j].date;
        delete this.arrayScv[j].customer;
        delete this.arrayScv[j].createdAt;
        delete this.arrayScv[j].comment;
        delete this.arrayScv[j].skippedBy;
        delete this.arrayScv[j].id;
        delete this.arrayScv[j].isReminded;
        delete this.arrayScv[j].remind;
        delete this.arrayScv[j].remindBy;
        delete this.arrayScv[j].__v;
      }

      let str =
        `${Object.keys(this.arrayScv[0])
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      let csvContent = this.arrayScv.reduce((st, next) => {
        st +=
          `${Object.values(next)
            .map((value) => `"${value}"`)
            .join(",")}` + "\r\n";
        return st;
      }, str);

      let element = document.createElement("a");
      element.href =
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
      element.target = "_blank";
      element.download = "orders.csv";
      element.click();
      await this.getOrders({ limit: this.limit });
    },
    sumQteProduct(data) {
      let sum = 0;
      for (let i in data) {
        sum = sum + data[i].quantity;
      }
      return sum;
    },
    setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async getStatusByName(name) {
      const result = await this.$server.get("status", { name: name });
      return result.content;
    },
    async CheckQteDefecteuseOrder(item) {
      this.CheckQteDefecteuseorderexist = false;

      if (item.status.name == "Return") {
        for (let i in item.details) {
          const filters = {
            product: item.details[i].product._id,
            order: item._id,
            shipping: null,
          };
          const res = await this.$server.search("stockHistories", filters);

          if (typeof res.content.results !== "undefined") {
            item.details[i].product.qteChange = res.content.results[0].quantity;
            this.CheckQteDefecteuseorderexist = true;
          }
        }
      }
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        countries: await this.warhouseSelected,
        limit: 4000,
      };
      if (this.currentUser.type != "Seller") {
        const res = await this.$server.search("users", filter);
        if (res.content.results) this.sellers = res.content.results;
        else this.sellers = [];
      }
    },
    async getProducts() {
      const filter = {
        "details.warehouse": this.idWharhouse._id,
      };

      const res = await this.$server.search("products", filter);

      if (res.content.results) this.products = res.content.results;
      else this.products = [];
    },

    async reset(data) {
      let lmitt = this.limit;
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      if (this.filters["limit"]) lmitt = this.filters["limit"];
      this.filters["page"] = 1;
      this.$f.objectToQueryString({
        limit: lmitt,
        "customer.country": await this.warhouseSelected,
      });
      await this.getOrders({
        limit: lmitt,
        "customer.country": await this.warhouseSelected,
      });
    },
    async filtrer(data) {
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      delete this.filters.page;
      delete this.filters.name;
      delete this.filters.country;
      this.filters["customer.country"] = await this.warhouseSelected;
      if (!this.filters["limit"]) this.filters["limit"] = this.limit;
      if (data.date) this.filters.date = data.date;
      else delete this.filters.date;
      if (data.Range_date) {
        this.filters.Range_date = data.Range_date.map((el) =>
          this.$moment(el).format("yyyy-MM-DD")
        );
      } else delete this.filters.Range_date;
      this.paginate.currentpage = 1;

      if (data.seller) this.filters.seller = data.seller._id;
      else delete this.filters.seller;
      if (data.product) this.filters["details.product"] = data.product._id;
      else delete this.filters["details.product"];
      if (data.status) this.filters.status = data.status._id;
      else delete this.filters.status;
      if (data.status_pending)
        this.filters.status_pending = data.status_pending.name;
      else delete this.filters.status_pending;

      this.$f.objectToQueryString(this.filters);

      await this.getOrders(this.filters);
    },
    /**********************************User and Status******************************************************/
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async getStatus() {
      let idCo = await this.getStatusByName("Confirmed");
      let idC = await this.getStatusByName("Cancelled");
      let idU = await this.getStatusByName("Unreached");
      let Pe = await this.getStatusByName("Pending");
      let Ou = await this.getStatusByName("OutOfStock");
      let Or = await this.getStatusByName("A transférer");
      let Aw = await this.getStatusByName("Awaiting preparation");
      let Spam = await this.getStatusByName("Spam");
      this.statusOrder = [Pe, Ou, idCo, idC, idU, Or, Aw, Spam];
      this.statusShippChange = [Ou, Pe];
    },
    async getZones() {
      if (this.currentUser.type != "Seller") {
        const res = await this.$server.search("zones", {
          limit: 1000,
          country: await this.warhouseSelected,
        });
        if (res.content.results) this.zones = res.content.results;
        else this.zones = [];
      } else {
        this.zones = [];
      }
    },
    getStatusId(status) {
      for (let i in this.statusOrder) {
        if (this.statusOrder[i].name == status) {
          return this.statusOrder[i]._id;
        }
      }
    },

    async assign(index) {
      if (!this.orders[index].teleConsultant) {
        this.orders[index].teleConsultant = this.currentUser._id;
        const res = await this.$server.update("orders", this.orders[index]);
        //
      }
    },
    /*******************************************Modal (View) update Chapping address and Status Order ************************************/

    /*********************************************************************************************************************************** */
    getAccess(modelToCheck, accessToCheck) {
      const permissions = this.currentUser.role.permissions;
      for (let i in permissions) {
        let model = permissions[i]["model"];
        let access = permissions[i]["access"];
        if (model == modelToCheck) {
          return access[accessToCheck];
        }
      }
    },
    async refresh() {
      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.$f.objectToQueryString(this.filters);
      if (this.filters.name) await this.search(this.filters.name);
      else await this.getOrders(this.filters);
      if (
        this.filters["page"] &&
        this.filters["page"] > 1 &&
        this.orders.length == 0
      ) {
        this.filters["page"] = --this.filters["page"];
        this.$f.objectToQueryString(this.filters);
        await this.getOrders(this.filters);
      }
    },
    showProducts(index) {
      if (this.element !== this.orders[index]) {
        this.element = this.orders[index];
        //
      } else this.element = null;
    },
    async getOrders(filters) {
      this.loading = true;
      filters["customer.country"] = await this.warhouseSelected;

      const res = await this.$server.search("orders", filters);

      this.loading = false;
      if (res.content.results) {
        this.orders = res.content.results;
        for (let i in this.orders) {
          for (let j in this.orders[i].details) {
            if (
              this.orders[i] &&
              this.orders[i].details[j] &&
              this.orders[i].details[j].unitPrice
            )
              this.orders[i].details[j].unitPrice =
                this.orders[i].details[j].unitPrice.toFixed(2);
          }
        }
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.orders = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async search(data) {
      this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      let idPending = await this.getStatusByName("Pending");
      let limitt = this.limit;
      if (this.filters["limit"]) limitt = this.filters["limit"];

      const search = {
        name: data.trim(),
        limit: parseInt(limitt),
      };
      if (this.currentUser.type == "Seller") {
        search.seller = this.currentUser._id;
      }
      if (this.currentUser.type == "StockManager") {
        search["status"] = idPending._id;
      }
      search["country"] = await this.warhouseSelected;
      this.$f.objectToQueryString(search);
      const res = await this.$server.find("orders", search);
      if (res.content.results) {
        this.orders = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.orders = [];
        this.paginate = { total: 0, currentpage: 1, lastpage: 1, per_page: 0 };
      }
    },
    async remove(id) {
      this.$swal.fire({
        title: this.$t("confirm_delete_order"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const resp = await this.$server.delete("orders", { id: id });
          await this.refresh();
          alert(this.$t("order_deleted"), "success");
        }
      });
    },
    /********************************************************************************************/
    async paginatation(direction) {
      var page = 0;
      if (direction == "next") {
        page = ++this.paginate.currentpage;
      } else {
        page = --this.paginate.currentpage;
      }

      if (this.$f.QueryStringToobject())
        this.filters = this.$f.QueryStringToobject();
      this.filters["page"] = page;
      this.$f.objectToQueryString(this.filters);

      this.getOrders(this.filters);
    },

    /*******************************************Fin Modal (View) update Chapping address and Status Order **********************************/
    /* ToDetail(id) {
      //:to="{ path: `/orders/detail/${item._id}` }"
      history.pushState(this.paginate, " ", `/orders/detail/${id}`);
      this.$router.push(`/orders/detail/${id}`);
    },*/
  },
};
</script>

<style>
/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}

dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/**********************************Table Product PopUp ***************************/

@media (min-width: 640px) {
  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
